<script>
export default {
  name: "CommissionDetails",

  props: {
    commission: {
      required: true,
      type: Number
    },
    currency: {
      type: String,
      required: true
    },
    record: {
      required: true,
      type: Object,
    },
    showCommission: {
      default: true,
      type: Boolean
    }
  },
}
</script>

<template>
  <span>
    {{ commission | currencyFormatter(currency) }}
    <template v-if="record.commission_type === 'percentage' && showCommission">
      ({{ record.commission_value | fixedDecimal }}%)
    </template>
    <template v-else-if="record.commission_type === 'flat_rate' && showCommission">
      ({{ record.commission_value | currencyFormatter(currency) }})
    </template>
  </span>
</template>
