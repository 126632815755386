<script>
import { mapGetters } from "vuex";
import AnimatedNumber from "animated-number-vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "Summary",

  mixins: [payoutsMixin],

  props: {
    summary: {
      type: Object,
      required: true
    },
  },

  components: {
    AnimatedNumber
  },

  computed: {
    showPayoutsSummary() {
      return !this.isViewingPayableOrders && this.summary?.payoutIds.length > 0 && this.$route.name === 'Payouts'
    },
    ...mapGetters("shop", ["currentShop"]),
  },

  methods: {
    formatToPrice(value) {
      return `$${value.toFixed(2)}`;
    },

    formatToValue(value) {
      return value.toFixed(0);
    }
  }
}
</script>

<template>
  <section class="summary">
    <!-- <aside class="summary__left hide-in-modal">
      <div class="summary__box">
        <span class="d-block tabular-fonts">
          <span class="placeholder-loading" v-if="loadingPayouts"></span>
          <animated-number
            :duration="350"
            :formatValue="formatToValue"
            :value="dashboardStats.fulfilled_orders"
          />
        </span>
        <span class="d-block">Fulfufilled orders</span>
      </div>
      <div class="summary__box">
        <span class="d-block tabular-fonts">
          <span class="placeholder-loading" v-if="loadingPayouts"></span>
          <animated-number
            :duration="350"
            :formatValue="formatToValue"
            :value="dashboardStats.unpaid_orders"
          />
        </span>
        <span class="d-block">Unpaid orders</span>
      </div>
      <div class="summary__box">
        <span class="d-block tabular-fonts">
          <span class="placeholder-loading" v-if="loadingPayouts"></span>
          <animated-number
            :duration="350"
            :formatValue="formatToPrice"
            :value="dashboardStats.total_sales"
          />
        </span>
        <span v-if="currentShop && currentShop.type === 'destination'" class="d-block">Total Sales</span>
        <span v-else class="d-block">Total Payouts</span>
      </div>
    </aside> -->
    <transition name="slide-x-reverse-transition">
      <aside class="summary__right" v-if="showPayoutsSummary">
        <div class="summary__box border">
          <span class="d-block tabular-fonts">
            <span class="placeholder-loading" v-if="loadindDashboardStats"></span>
            <animated-number
              :duration="350"
              :formatValue="formatToValue"
              :value="summary.payoutIds.length"
            />
          </span>
          <span class="d-block">Selected</span>
        </div>
        <div class="summary__box">
          <span class="d-block tabular-fonts">
            <span class="placeholder-loading" v-if="loadindDashboardStats"></span>
            <animated-number
              :duration="350"
              :formatValue="formatToPrice"
              :value="payoutsDashboard.total"
            />
          </span>
          <span class="d-block">Payout total</span>
        </div>
        <div class="summary__box">
          <span class="d-block tabular-fonts">
            <span class="placeholder-loading" v-if="loadindDashboardStats"></span>
            <animated-number
              :duration="350"
              :formatValue="formatToPrice"
              :value="payoutsDashboard.sales - payoutsDashboard.total"
            />
          </span>
          <span class="d-block">Commissions</span>
        </div>
      </aside>
    </transition>
  </section>
</template>
