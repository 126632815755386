<script>
import { CirclePlus, NoteIcon, TrashIcon, CircleMinus } from "@/icons";
import { FETCH_CURRENT_ORDER } from "@/store/actions.type";
import CommissionDetails from "@/views/payouts/components/CommissionDetails.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "InvoicedOrder",

  data() {
    return {
      areProductsVisible: false
    }
  },

  mixins: [payoutsMixin],

  components: {
    CircleMinus,
    CirclePlus,
    CommissionDetails,
    NoteIcon,
    TrashIcon,
  },

  props: {
    payout: {
      type: Object,
      required: true
    },

    isPreviewMode: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    async loadCurrentOrder(item) {
      this.isOrderDetailsVisible = true;

      await this.$store.dispatch(`orders/${FETCH_CURRENT_ORDER}`, {
        storeId: this.currentShop.id,
        orderId: item.order_id,
      });
    },
  }
}
</script>

<template>
  <tbody>
    <tr>
      <td>
        <span class="push-bottom" @click="areProductsVisible = !areProductsVisible">
          <CircleMinus v-if="areProductsVisible" />
          <CirclePlus v-else />
        </span>
      </td>
      <td class="pl-0">
        <a href="javascript:void(0);" @click="loadCurrentOrder(payout)" class="link-primary" v-if="currentShop.type === 'destination'">
          <NoteIcon /> {{ payout.order_number }}
        </a>
        <span v-else>
          {{ payout.order_number }}
        </span>
      </td>
      <td>{{ payout.source_order_number || '-' }}</td>
      <td>{{ payout.line_item_count }}</td>
      <td class="tabular-fonts">{{ payout.total_price | currencyFormatter(currency) }}</td>
      <td class="text-success tabular-fonts">
        +<CommissionDetails :commission="payout.commission" :record="payout" :currency="currency" :showCommission="false" />
      </td>
      <td class="tabular-fonts"><strong>{{ payout.payable | currencyFormatter(currency) }}</strong></td>
      <td class="text-center">
        <span @click="removePayoutFromInvoicedOrders(payout)" class="clear-btn" v-if="isPreviewMode">
          <span class="push-bottom"><TrashIcon /></span>
        </span>
        <span v-else>-</span>
      </td>
    </tr>
    <tr v-if="areProductsVisible">
      <td colspan="10" style="padding: 0 !important;">
        <v-simple-table class="products-table">
          <template>
            <thead>
              <tr>
                <th style="width: 8%; background: transparent; border-right: solid 16px #0E3B4D;"></th>
                <th style="width: 17%;" class="pl-0">Product</th>
                <th style="width: 15%;">SKU</th>
                <th style="width: 8%;">Qty</th>
                <th style="width: 15%;">Value</th>
                <th style="width: 16%;">Commission</th>
                <th style="width: 12%;">Payable</th>
                <th style="width: 9%; background: transparent;"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in payout.products" :key="product.sku">
                <td>
                  <img :src="product.image" :alt="product.name" style="width: 30px;">
                </td>
                <td class="truncate-text pl-0">{{ product.name }}</td>
                <td class="truncate-text">{{ product.sku }}</td>
                <td>{{ product.quantity }}</td>
                <td class="tabular-fonts">{{ product.price | currencyFormatter(currency) }}</td>
                <td class="text-success tabular-fonts">+<CommissionDetails :commission="product.commission" :record="product" :currency="currency" /></td>
                <td class="tabular-fonts">{{ product.payable | currencyFormatter(currency) }}</td>
                <td style="background: transparent;"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </tr>
  </tbody>
</template>
