<script>
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "PaidPayoutsCheckbox",

  mixins: [payoutsMixin],

  data() {
    return {
      isCheckboxChecked: false,
    }
  },

  mounted() {
    this.isCheckboxChecked = this.isPayoutSelected();
  },

  watch: {
    'bulkPaidPayouts'() {
      this.isCheckboxChecked = this.isPayoutSelected();
    },
  },

  props: {
    payout: {
      required: true,
      type: Object
    }
  },

  methods: {
    isPayoutSelected() {
      const { payout_id } = this.payout;
      return this.bulkPaidPayouts.includes(payout_id);
    },
  }
}
</script>

<template>
  <v-checkbox
    @change="selectBulkPaidPayouts($event, payout)"
    dense
    hide-details
    v-model="isCheckboxChecked" />
</template>
