import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'payouts/getField',
  mutationType: 'payouts/updateField'
});

export default {
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      startOfToday: new Date().toLocaleString("en-US", { "timeZone": this.timeZone })
    }
  },

  computed: {
    ...mapFields(["timeZone"]),
  },

  methods: {
    setDateAndTime(day, currentDateTime, daysToSubtract, isStartOfDay) {
      day = new Date(currentDateTime);
      day.setDate(day.getDate() - daysToSubtract);
      isStartOfDay ? day.setHours(0, 0, 0) : day.setHours(23, 59, 59);
      return day;
    },

    setDefaultStartAndEndDate() {
      let currentDateTime = new Date().toLocaleString("en-US", { "timeZone": this.timeZone });
      this.dateRange.startDate = this.setDateAndTime(this.dateRange.startDate, currentDateTime, 30, true);
      this.dateRange.endDate = this.setDateAndTime(this.dateRange.endDate, currentDateTime, 0, false);
    },
  }
}
