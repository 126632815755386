<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { createHelpers } from 'vuex-map-fields';
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";

const { mapFields } = createHelpers({
  getterType: 'payouts/getField',
  mutationType: 'payouts/updateField'
});

export default {
  name: "DateRangeFilter",

  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
    }
  },

  created() {
    this.setDefaultStartAndEndDate();
  },

  watch: {
    'startDate'(value) {
      this.dateRange.startDate = value;
    },

    'endDate'(value) {
      this.dateRange.endDate = value;
    }
  },

  props: {
    opens: {
      default: "left",
      required: false,
      type: String
    },

    startDate: {
      required: true,
      type: String
    },

    endDate: {
      required: true,
      type: String
    },
  },

  computed: {
    ...mapFields(["timeZone"]),

    maxDate() {
      let currentDateTime = new Date().toLocaleString("en-US", { "timeZone": this.timeZone });
      let endOfToday = this.setDateAndTime(endOfToday, currentDateTime, 0, false);
      return endOfToday;
    },

    ranges() {
      let currentDateTime = new Date().toLocaleString("en-US", { "timeZone": this.timeZone });

      let startOfToday = null, endOfToday = null;
      startOfToday = this.setDateAndTime(startOfToday, currentDateTime, 0, true);
      endOfToday = this.setDateAndTime(endOfToday, currentDateTime, 0, false);

      let startOfYesterday = null, endOfYesterday = null;
      startOfYesterday = this.setDateAndTime(startOfYesterday, currentDateTime, 1, true);
      endOfYesterday = this.setDateAndTime(endOfYesterday, currentDateTime, 1, false);

      let startOfLastSevenDays = null;
      startOfLastSevenDays = this.setDateAndTime(startOfLastSevenDays, currentDateTime, 7, true);

      let startOfLastThirthDays = null;
      startOfLastThirthDays = this.setDateAndTime(startOfLastThirthDays, currentDateTime, 30, true);

      let startOfLastNintyDays = null;
      startOfLastNintyDays = this.setDateAndTime(startOfLastNintyDays, currentDateTime, 90, true);

      return {
        "Today": [startOfToday, endOfToday],
        "Yesterday": [startOfYesterday, endOfYesterday],
        "Last 7 Days": [startOfLastSevenDays, endOfToday],
        "Last 30 Days": [startOfLastThirthDays, endOfToday],
        "Last 90 Days": [startOfLastNintyDays, endOfToday],
      };
    },
  },

  components: {
    DateRangePicker
  },

  methods: {
    setDateAndTime(day, currentDateTime, daysToSubtract, isStartOfDay) {
      day = new Date(currentDateTime);
      day.setDate(day.getDate() - daysToSubtract);
      isStartOfDay ? day.setHours(0, 0, 0) : day.setHours(23, 59, 59);
      return day;
    },

    setDefaultStartAndEndDate() {
      let currentDateTime = new Date().toLocaleString("en-US", { "timeZone": this.timeZone });
      this.dateRange.startDate = this.setDateAndTime(this.dateRange.startDate, currentDateTime, 30, true);
      this.dateRange.endDate = this.setDateAndTime(this.dateRange.endDate, currentDateTime, 0, false);
    },

    setDateTimeLabel(totalDifference) {
      switch(totalDifference) {
        case 0:
          return "Today";

        case 2:
          return "Yesterday";

        case 7:
          return "Last 7 Days";

        case 30:
          return "Last 30 Days";

        case 90:
          return "Last 90 Days";

        default:
          return moment(this.dateRange.startDate).format("MMM Do[,] YYYY") + " - " + moment(this.dateRange.endDate).format("MMM Do[,] YYYY");
      }
    },

    updateHandler({ endDate, startDate }) {
      let currentDateTime = new Date().toLocaleString("en-US", { "timeZone": this.timeZone });
      const differenceFromStartDate = moment(currentDateTime).startOf('day').diff(moment(startDate).startOf('day'), 'days', true);
      const differenceFromEndDate = moment(currentDateTime).startOf('day').diff(moment(endDate).startOf('day'), 'days', true);
      const label = this.setDateTimeLabel(differenceFromStartDate + differenceFromEndDate);
      const rangeFilter = `${startDate} to ${endDate}`;
      this.$emit('onDateRangeSelected', {
        endDate,
        label,
        rangeFilter,
        startDate
      });
    }
  }
}
</script>

<template>
  <date-range-picker
    :maxDate="maxDate"
    :opens="opens"
    :ranges="ranges"
    :showDropdowns="false"
    @update="updateHandler"
    format="yyyy-mm-dd"
    ref="dateTimePicker"
    v-model="dateRange">
    <template v-slot:footer="dateTimePicker">
      <div class="drp-buttons">
        <button type="button" @click="dateTimePicker.clickCancel" class="cancelBtn btn btn-sm btn-secondary">Cancel</button>
        <button :disabled="dateTimePicker.in_selection !== false" @click="dateTimePicker.clickApply" type="button" class="applyBtn btn btn-sm btn-success">Apply</button>
      </div>
    </template>
  </date-range-picker>
</template>
