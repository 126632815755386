<template>
  <div class="order-detials-block">
    <v-card class="content" elevation="0" v-click-outside="closeDialog" width="950px">
      <v-row justify="center" v-if="isLoadingCurrentOrder">
        <v-col cols="12" align="center">
          <div style="position: absolute; top: 40%; left: 40%;">
            <v-img class="rotate" src="~@/assets/images/logo.svg" height="98px" width="75px" contain></v-img>
            <p class="text-h4 mt-3">Please wait...</p>
          </div>
        </v-col>
      </v-row>
      <div v-else>
        <v-row class="px-7 mt-3">
          <v-col cols="9">
            <p class="head-text pb-1">PUSH ORDER SUMMARY</p>
            <span style="margin-left: -5px;" class="product-sku">
              <svg style=" display: inline-block; vertical-align: middle;" width="30" height="28" viewBox="0 0 541 728" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M272.839 90.9023V11.2127C272.839 2.59293 263.511 -2.79135 256.044 1.51442L9.32715 143.959C1.8641 148.269 1.8641 159.041 9.32715 163.351L137.232 237.195C170.678 202.412 217.655 180.731 269.719 180.731C371.246 180.731 453.545 263.03 453.545 364.557C453.545 382.982 450.804 400.761 445.761 417.544L521.182 461.746C533.667 430.642 540.554 396.682 540.554 361.112C540.554 212.703 420.923 92.2854 272.839 90.9023Z" fill="#FB757C" />
                <path d="M523.381 564.65L400.502 493.708C367.176 527.454 320.899 548.382 269.72 548.382C168.197 548.382 85.893 466.083 85.893 364.555C85.893 353.202 86.9756 342.105 88.9474 331.32L10.7438 285.483C3.75825 309.486 0 334.86 0 361.114C0 506.893 115.432 625.647 259.869 631.126V716.789C259.869 725.408 269.201 730.793 276.664 726.483L523.381 584.043C530.844 579.733 530.844 568.96 523.381 564.65Z" fill="#FDB159" />
                <path d="M157.88 364.556C157.88 426.323 207.952 476.395 269.719 476.395C331.486 476.395 381.562 426.323 381.562 364.556C381.562 302.79 331.486 252.718 269.719 252.718C207.952 252.718 157.88 302.79 157.88 364.556" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient id="paint0_linear" x1="176.603" y1="454.448" x2="349.842" y2="287.212" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDAF5C" />
                    <stop offset="0.924847" stop-color="#FA747D" />
                    <stop offset="1" stop-color="#FA747D" />
                  </linearGradient>
                </defs>
              </svg>

              <span class="status-span mx-1">Status:</span>

              <v-chip class="ml-2" :class="getPushStatusClass(this.currentOrder.push_status)">
                <v-icon class="chip-icon-color">{{getPushStatusIcons(this.currentOrder.push_status)}}</v-icon>
                <span class="ml-2 chip-font">{{getPushStatus(this.currentOrder.push_status)}}</span>
              </v-chip>
            </span>

            <span style="font-size: 12px;font-weight: 100;color:#495057" class="d-block mt-2">Order contains synced products from
              <b style="font-weight: 600">{{this.getNumberOfStore(this.currentOrder.source_stores)}}</b>.
            </span>
          </v-col>

          <v-col cols="3" style="padding-top:0 !important; ">
            <div class="text--primary top-bar fixed-bar">
              <v-btn class="transparent elevation-0" style="float: right" v-on:click="closeDialog">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 24 24" style=" fill:#000000;">
                    <path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z"></path>
                  </svg>
                </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <hr style=" margin: 20px 30px" />

        <v-row class="px-7 mb-2">
          <v-col cols="8">
            <v-col class="store-item-card not-a-link card-rounded pa-6">
              <div class="per-width">
                <img src="@/assets/images/shopify_logo.png" alt="shopify" height="30px" width="30px" style="display: inline-block;vertical-align: middle" />
                <span class="head-text ml-2" style="vertical-align: middle">MY ORDER DETAILS: {{ this.currentOrder.name }}</span>
              </div>
              <div class="mt-3 per-width">
                <span class="status-span">Status:</span>
                <v-chip class="ml-2" :class="getFinancialStatusClass()">
                  <v-icon class="chip-color">
                    {{ getFinancialStatusIcons() }}
                  </v-icon>
                  <span class="chip-font ml-2">{{getFinancialStatus()}}</span>
                </v-chip>
                <v-chip class="ml-2" :class="getFulfillmentStatusClass()">
                  <v-icon class="chip-color">
                    {{ getFulfillmentStatusIcons() }}
                  </v-icon>
                  <span class="chip-font ml-2">{{getFulfillmentStatus()}}</span>
                </v-chip>
              </div>
              <div class="order-reference-id mt-2 per-width">
                <a target="_blank" :href="`https://${currentShop.store_domain}/admin/orders/${currentOrder.id}`">Order ID: {{ this.currentOrder.id }}</a>
              </div>
              <div class="mt-1 order-date per-width" style="color:#495057">
                <b>Order Created:</b>
                {{ new Date(this.currentOrder.created_at).toLocaleString("en-US", {timeZone: "Australia/Sydney"}) | moment("DD MMM [at] hh:mm A") }}
              </div>
              <div class="mt-1 order-date per-width" style="color:#495057" v-if="currentOrder.edited !== false && currentOrder.edited_at !== null">
                <b>Last Edited at:</b>
                {{ new Date(this.currentOrder.edited_at).toLocaleString("en-US", {timeZone: "Australia/Sydney"}) | moment("DD MMM [at] hh:mm A") }}
              </div>
            </v-col>
            <v-col class="mt-6" style="padding: 0 !important;">
              <v-row v-if="currentOrder.edited">
                <v-col>
                  <v-alert class="alert-error" icon="error" type="error">
                    <h4 class="mb-2">This order has been edited. (Last edited at {{ new Date(this.currentOrder.edited_at).toLocaleString("en-US", {timeZone: "Australia/Sydney"}) | moment("DD MMM [at] hh:mm A") }} (AEST)</h4>
                    <p class="mb-0">The line item/ quantity edits have been pushed to the source store. Please verify if the Source Store has received the right items and quantities.</p>
                  </v-alert>
                </v-col>
              </v-row>
              <v-card class=" store-item-card not-a-link card-rounded pa-6 mt-6" v-for="(storeOrder, storeName) in this.currentOrder.source_stores" :key="storeName" :set="(v = $v.shippingCost.$each[storeName])">
                <div outlined :class="{ 'push-failed': storeOrder.push_status === 'failed' }">
                  <v-row>
                    <v-col class="text-no-wrap">
                      <v-icon class="navy">store</v-icon>
                      <span class="store-name navy ml-2">{{ storeName }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-if="storeOrder.push_status !== 'pushed' && storeOrder.push_status !== 'blocked' && storeOrder.is_mapper_deleted!==true && storeOrder.store_disconnected!==true" cols="4">
                      <v-text-field
                              v-if="!(currentOrder.customer === null || currentOrder.shipping_address === null)"
                              light
                              outlined
                              dense
                              background-color="white"
                              class="shipping-cost-input pr-5 elevation-0"
                              v-model="shippingCost[storeName]"
                              :error-messages="updateShippingCostInputStatus(storeName)"
                              prefix="$"
                              @change="$v.$touch()">
                        <template v-slot:label>
                          <span style="font-size: 13px; margin-left: 3px;">Enter a shipping fee</span>
                        </template>
                      </v-text-field>
                      <div v-if="!(currentOrder.customer === null || currentOrder.shipping_address === null)"
                           class="divider"></div>
                    </v-col>

                    <v-col class="text-right" v-if="storeOrder.push_status !== 'blocked'">
                      <v-btn
                              block
                              height="41px"
                              width="182px"
                              color="primary"
                              class="push-order-button transparent"
                              v-on:click="pushOrderToSourceStore(storeOrder.target_store_id, storeName)"
                              v-if="currentOrder.customer != null && currentOrder.shipping_address != null && storeOrder.push_status != 'pushed' && (v != null && !v.$invalid) && storeOrder.is_mapper_deleted!==true
                              && storeOrder.store_disconnected!==true" :loading="storeOrder.push_status === 'pushing'">
                        <div v-if="storeOrder.is_mapper_deleted!==true && storeOrder.store_disconnected!==true">
                          <span v-if="storeOrder.push_status === 'failed'">Repush</span>
                          <span v-else>Push Order</span>
                        </div>
                      </v-btn>

                      <v-btn
                              block
                              height="41px"
                              width="182px"
                              class="pushed-button text-right float-right pa-0 transparent elevation-0 disable-events justify-end"
                              v-else-if="storeOrder.push_status === 'pushed'">
                        <v-icon color="success">check_circle</v-icon>
                        <div class="ml-2">Pushed</div>
                      </v-btn>
                      <v-btn
                              block
                              height="41px"
                              width="182px"
                              class="float-right elevation-0 disable-events disabled-push-button"
                              v-else>
                        <span v-if="storeOrder.push_status === 'failed'">Repush</span>
                        <span v-else>Push Order</span>
                      </v-btn>
                      <small class="pushed-date"
                             v-if="typeof(storeOrder.pushed_at) != 'undefined' && storeOrder.pushed_at != null">
                        On {{ storeOrder.pushed_at | moment('MMM DD') }} at {{ storeOrder.pushed_at | moment('hh:mm A')
                        }} (AEST)
                      </small>
                    </v-col>

                    <v-col v-else class="text-right pr-0">
                      <div v-if="storeOrder.is_mapper_deleted!==true && storeOrder.store_disconnected!==true">
                        <v-chip class="mb-1" color="#D91E18" text-color="white">
                          <span class="text-capitalize">{{ storeOrder.push_status }}</span>
                        </v-chip>
                        <p style="color:#D91E18" class="mt-1 mb-0" v-if="currentOrder.push_status==='pushed'">Location
                          changed</p>
                        <p style="color:#D91E18" class="mt-1 mb-0" v-else>blocked by Syncio location mismatching</p>
                      </div>

                    </v-col>
                    <v-col class="text-no-wrap pt-0" v-if="storeOrder.is_mapper_deleted==true && storeOrder.store_disconnected!==true">
                    <div><span style=" color: red;" class="pl-4" >
                      Cannot fetch info as some product(s) are unsynced on {{ new Date(storeOrder.mapper_deleted_at).toLocaleString("en-US", {timeZone: "Australia/Sydney"}) | moment("DD MMM") }} (AEST)
                    </span></div>
                    </v-col>
                    <v-col class="text-no-wrap pt-0" v-if="storeOrder.store_disconnected==true">
                    <div><span style="color: red;" class="pl-4">
                      Cannot fetch info as store is disconnected
                    </span></div>
                    </v-col>

                  </v-row>

                  <v-row v-if="storeOrder.push_status === 'failed' && storeOrder.is_mapper_deleted!==true ">
                    <v-col cols="1">
                      <v-icon color="#FF0000">
                        error
                      </v-icon>
                    </v-col>
                    <v-col class="ml-n5">
                      <span class="warning-text red--text font-weight-bold">
                        An error has occurred while pushing your order to one or
                        more source stores. Please click ‘repush’ to try again.
                      </span>
                    </v-col>
                  </v-row>

                  <v-row class="mt-7 mb-0 product-details-color" v-for="lineItem in storeOrder.line_items" :key="lineItem.sku">
                    <v-col cols="6">
                      <div class="d-flex">
                        <div class="product-image">
                          <v-img
                            v-if="lineItem.image"
                            :src="lineItem.image"
                            contain
                          ></v-img>
                          <v-img
                            v-else
                            src="@/assets/images/placeholder.png"
                            contain
                          ></v-img>
                        </div>
                        <div class="ml-3">
                          <span class="product-title">{{ lineItem.title }}</span
                          ><br />
                          <span class="product-sku"
                            >SKU: {{ lineItem.sku }}</span
                          >
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="3" style="text-align: right">
                      <span class="quntity"
                        >${{ parseFloat(lineItem.unit_price).toFixed(2) }} x
                        {{ lineItem.quantity }}</span
                      >
                    </v-col>
                    <v-col style="text-align: right">
                      <span class="quntity"
                        >${{
                          parseFloat(lineItem.total_price).toFixed(2)
                        }}</span
                      >
                    </v-col>
                  </v-row>

                  <!-- <OrderMessages :storeOrder="storeOrder" /> -->

                </div>

              </v-card>
            </v-col>
          </v-col>
          <v-col cols="4">
            <v-col class="store-item-card not-a-link card-rounded pa-6 m-3" style="min-height:162px">
              <p class="head-text per-width">NOTES</p>
              <div>
                <span v-if="currentOrder.note != null && currentOrder.note !== ''" class="per-width notes">{{ this.currentOrder.note }}</span>
                <span class="per-width" v-else style="color: #212429">No notes from customer</span>
              </div>
            </v-col>
            <v-col
                class="store-item-card not-a-link card-rounded pa-6 m-3 mt-6"
                style="min-height:162px"
                v-if="Object.keys(currentOrder.additional_notes).length >= 1">
              <p class="head-text per-width">ADDITIONAL NOTES</p><br>
              <div
                v-for="(note, index) in currentOrder.additional_notes"
                :key="index"
              >
                <span class="per-width" style="color: #212429" v-if="index=='SYNCIO ORDER INFORMATION'">{{ index }}</span>
                <span class="per-width" style="color: #212429" v-else>{{ index }}</span><br>
                <p class="per-width notes" v-if="index!==0" style="color: #4E4E4E">{{ note }}</p>
              </div>
            </v-col>

            <v-col class="store-item-card not-a-link card-rounded pa-6 m-3 mt-6" style="min-height:162px">
              <p class="head-text per-width">CUSTOMER</p>
              <span class="per-width notes">{{ this.currentOrder.contact_details && this.currentOrder.contact_details.name }}</span
              >
              <hr class="separator-hr" />
              <p class="head-text per-width mt-2">CONTACT INFORMATION</p>
              <span
                class="per-width notes"
                >{{ this.currentOrder.contact_details && this.currentOrder.contact_details.email }}</span
              >
              <hr class="separator-hr" />
              <p
                class="head-text per-width mt-2"
                v-if="currentOrder.customer != null"
              >
                SHIPPING ADDRESS
              </p>
              <template v-if="currentOrder.shipping_address">
                <span
                  v-if="currentOrder.shipping_address.address1 != null"
                  class="per-width notes"
                  style="color: #333333"
                  >{{ this.currentOrder.shipping_address.address1 }}</span
                ><br />
                <span
                  v-if="currentOrder.shipping_address.city != null"
                  class="per-width notes"
                  style="color: #333333"
                  >{{ this.currentOrder.shipping_address.city }}
                  {{ this.currentOrder.shipping_address.province }}</span
                ><br />
                <span
                  v-if="currentOrder.shipping_address.zip != null"
                  class="per-width notes"
                  style="color: #333333"
                  >{{ this.currentOrder.shipping_address.zip }}
                  {{
                    this.currentOrder.shipping_address.country
                  }}</span
                >
              </template>
            </v-col>
            <v-col
              class="store-item-card not-a-link card-rounded pa-6 m-3 mt-6"
              style="min-height:162px "
            >
              <p class="head-text per-width">TAGS</p>
              <div>
                <v-chip
                  v-for="(tag, index) in currentOrder.tags"
                  :key="index"
                  small
                  class="mr-1 mb-1 tag-chip"
                >
                  {{ tag }}
                </v-chip>
              </div>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import { ADD_NOTIFICATION, FETCH_CURRENT_ORDER, HIDE_SINGLE_ORDER, LAUNCH_STORE_ORDER_PUSHING_STATUS, PUSH_ORDER_TO_TARGET_STORE } from "@/store/actions.type";
import { mapGetters, mapState } from "vuex";
import { required, decimal, minValue } from "vuelidate/lib/validators";
// import OrderMessages from "../components/OrderMessages.vue";
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'orders_2_0/getField',
  mutationType: 'orders_2_0/updateField',
});

export default {
  name: "OrderDetailsBlock",

  data: function() {
    return {
      shippingCost: [],
    };
  },

  // components: {
  //   OrderMessages
  // },

  validations: {
    shippingCost: {
      $each: {
        required,
        decimal,
        minValue: minValue(0.0),
      },
    },
  },

  props: ['orderActiveStatus'],

  computed: {
    ...mapGetters("orders", [
      "showSingleOrder",
      "currentOrder",
      "isLoadingCurrentOrder",
    ]),
    ...mapFields([
      'orderMessages',
      'nextPageUrl'
    ]),
    ...mapState('orders', ['pushSettings']),
    ...mapGetters("shop", ["currentShop"]),
    ...mapState('auth', ['user']),

    // eslint-disable-next-line vue/return-in-computed-property
    filteredSettings() {
      let value = '';
      this.pushSettings.forEach((setting) => {
        if (setting.key == 'push_order_email_option') {
          value = setting.value;
        }
      });
      return value;
    }
  },

  methods: {
    updateShippingCostInputStatus(storeName) {
      const newShippingCostErrors = [];
      if (
        typeof this.shippingCost[storeName] === "undefined" ||
        !this.$v.shippingCost.$each[storeName].$dirty
      ) {
        return newShippingCostErrors;
      }
      !this.$v.shippingCost.$each[storeName].required &&
        newShippingCostErrors.push("Must be a valid number");
      !this.$v.shippingCost.$each[storeName].decimal &&
        newShippingCostErrors.push("Must be a valid number");
      !this.$v.shippingCost.$each[storeName].minValue &&
        newShippingCostErrors.push("Must be equal or bigger than zero");
      return newShippingCostErrors;
    },

    closeDialog() {
      this.$store.dispatch(`orders/${HIDE_SINGLE_ORDER}`);
      this.orderMessages = null;
      this.nextPageUrl = null;
    },

    getNumberOfStore(source_stores) {
      return Object.keys(source_stores).length > 1
        ? Object.keys(source_stores).length + " source stores"
        : Object.keys(source_stores).length + " source store";
    },

    pushOrderToSourceStore(targetStoreId, storeName) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let newShippingCost = this.shippingCost[storeName];
        this.$store.dispatch(
          `orders/${LAUNCH_STORE_ORDER_PUSHING_STATUS}`,
          targetStoreId
        );
        this.$store
          .dispatch(`orders/${PUSH_ORDER_TO_TARGET_STORE}`, {
            currentStoreId: this.currentShop.id,
            currentOrderId: this.currentOrder.syncio_order_id,
            targetStoreId: targetStoreId,
            shippingCost: newShippingCost,
          })
          .then((data) => {
            if (data.success === true) {
              this.displayOrderPushNotification(
                "bottom-left",
                "success",
                data.message
              );
            } else {
              this.displayOrderPushNotification(
                "bottom-left",
                "error",
                data.message
              );
            }
          });
      }
    },

    displayOrderPushNotification(position, status, message) {
      this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
        notification: {
          id: "N" + Math.floor(Math.random() * 100000000),
          position: position,
          type: status,
          body: message,
          state: 0,
          length: 6000, // seconds
          data: null,
        },
      });
    },

    getOrderPushedStatus() {
      const noOfTargetStores = Object.keys(this.currentOrder.source_stores)
        .length;
      const allPushedStatus = Object.values(
        this.currentOrder.source_stores
      ).map((value) =>
        value.push_status === "pushed"
          ? true
          : value.push_status === "failed"
          ? "failed"
          : false
      );
      const countOfStatus = _.countBy(allPushedStatus);
      if (countOfStatus.true === noOfTargetStores) {
        return "pushed";
      } else if (countOfStatus.false === noOfTargetStores) {
        return "not pushed";
      } else if (countOfStatus.failed > 0) {
        return "failed";
      } else {
        return "partial pushed";
      }
    },

    loadCurrentOrder(orderId) {
      this.$store.dispatch(`orders/${FETCH_CURRENT_ORDER}`, {
        storeId: this.currentShop.id,
        orderId: orderId,
      });
    },

    getFinancialStatusClass() {
      let financialStatus = this.currentOrder.financial_status;
      let financialStatusClasses = {
        authorised: "paid-chip",
        pending: "payment-pending-chip",
        paid: "paid-chip",
        partially_paid: "partially-paid-chip",
        refunded: "refunded-chip",
        voided: "refunded-chip",
        partially_refunded: "refunded-chip",
        unpaid: "not-paid-chip",
      };
      return financialStatusClasses[financialStatus];
    },

    getFinancialStatus() {
      let financialStatusFromShopify = this.currentOrder.financial_status;
      let financialStatus = {
        authorised: "Authorised",
        pending: "Pending",
        paid: "Paid",
        partially_paid: "Partially Paid",
        refunded: "Refunded",
        voided: "Voided",
        partially_refunded: "Partially Refunded",
        unpaid: "Unpaid",
      };
      return financialStatus[financialStatusFromShopify];
    },

    getFinancialStatusIcons() {
      let financialStatusFromShopify = this.currentOrder.financial_status;
      let financialStatusIcons = {
        authorised: "circle",
        pending: "trip_origin",
        paid: "circle",
        partially_paid: "tonality",
        refunded: "report_problem",
        voided: "report_problem",
        partially_refunded: "report_problem",
        unpaid: "trip_origin",
      };
      return financialStatusIcons[financialStatusFromShopify];
    },

    getFulfillmentStatusClass() {
      let fulfillmentStatus = this.currentOrder.fulfillment_status;
      let fulfillmentStatusClasses = {
        fulfilled: "paid-chip",
        partial: "partially-paid-chip",
        unfulfilled: "partially-paid-chip",
        restocked: "partially-paid-chip"
      };
      return fulfillmentStatusClasses[fulfillmentStatus];
    },

    getFulfillmentStatus() {
      let fulfillmentStatusFromShopify = this.currentOrder.fulfillment_status;
      let fulfillmentStatus = {
        partial: "Partially fulfilled",
        fulfilled: "Fulfilled",
        unfulfilled: "Unfulfilled",
        restocked: "Restocked"
      };
      return fulfillmentStatus[fulfillmentStatusFromShopify];
    },

    getFulfillmentStatusIcons() {
      let fulfillmentStatusFromShopify = this.currentOrder.fulfillment_status;
      let fulfillmentStatusIcons = {
        fulfilled: "circle",
        partial: "tonality",
        unfulfilled: "trip_origin",
        restocked: "circle"
      };
      return fulfillmentStatusIcons[fulfillmentStatusFromShopify];
    },

    getPushStatusClass(pushStatus) {
      let pushStatusClasses = {
        not_pushed: "not-push-chip",
        pushed: "push-chip",
        failed: "failed-chip",
        invalid: "failed-chip",
        partially_pushed: "partially-push-chip",
      };
      return pushStatusClasses[pushStatus];
    },

    getPushStatus(pushStatus) {
      let pushStatusText = {
        not_pushed: "Not Pushed",
        pushed: "Pushed",
        failed: "Failed",
        invalid: "Invalid",
        partially_pushed: "Partially Pushed",
      };
      return pushStatusText[pushStatus];
    },

    getPushStatusIcons(pushStatus) {
      let pushStatusIcons = {
        not_pushed: "trip_origin",
        pushed: "circle",
        failed: "report_problem",
        invalid: "report_problem",
        partially_pushed: "tonality",
      };
      return pushStatusIcons[pushStatus];
    },

    async createOrderMessages(storeOrder) {
      await this.$store.dispatch('orders_2_0/createOrderMessages', {
        order_id: this.currentOrder.syncio_order_id,
        store_id: storeOrder.target_store_id,
        description: "A test message is created",
        created_by: this.user.id
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed-bar {
  height: 60px;
  flex-flow: row-reverse;
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  z-index: 2;
}

.head-text {
  margin-bottom: 6px !important;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
}

.content {
  border-radius: 0px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  color: #333;
  height: 100%;
  top: 0;
  right: 0;
  min-width: 800px;
  max-width: 1000px;
}

.orderId {
  font-style: normal;
  font-weight: normal;
  font-size: 18.16px;
  line-height: 25px;
}

.paid-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #ecffec !important;

  .chip-color {
    color: #28a228 !important;
    font-size: 8.33px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #085f07;
    text-transform: capitalize;
  }
}

.partially-paid-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #fff2e3 !important;

  .chip-color {
    color: #aa5200 !important;
    font-size: 8.33px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #aa5200;
    text-transform: capitalize;
  }
}

.not-paid-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #f8f9fa !important;

  .chip-color {
    color: #495057 !important;
    font-size: 8.33px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #495057;
    text-transform: capitalize;
  }
}

.payment-pending-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #fcb058 !important;

  .chip-color {
    color: #495057 !important;
    font-size: 8.33px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #495057 !important;
    text-transform: capitalize;
  }
}

.refunded-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #ffecec !important;

  .chip-color {
    color: #ff0000 !important;
    font-size: 11px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ff0000 !important;
    text-transform: capitalize;
  }
}

.push-status-chip {
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 24px;
}

.push-status-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #495057;
}

.order-reference-id {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.order-date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.customer-details {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.order-contains-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20.88px;
  line-height: 34px;
}

.click-push-order-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.navy {
  color: #0e3b4d;
}

.store-item-card {
  background-color: rgb(248, 248, 250);
  //background-color: #ccc;
  border-color: rgb(248, 248, 250);
  color: #f8f8fa;

  .push-order-button {
    color: #fff;

    &.transparent {
      background: #143b4d !important;
    }
  }

  &.push-failed {
    border-color: #f44336 !important;

    .push-order-button,
    .disabled-push-button {
      background-color: #539dbd !important;
      border-color: #539dbd !important;
      color: #fff;
      opacity: 0.5;
    }

    .push-order-button {
      opacity: 1;
    }
  }
}

.store-name {
  font-weight: 600;
  font-size: 18.16px;
  line-height: 25px;
}

.shipping-cost-input {
  float: right;
  margin-left: -4px;
  border-radius: 5px;
  width: 190px;
}

.divider {
  height: 50px;
  border-right: 1px solid #ccc;
  padding-left: 5px;
  display: block;
}

.push-order-button {
  float: left;
  border: 1px solid #acb5bd;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #495057;
}

.pushed-button {
  border: 1px solid #acb5bd;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #495057;
}

.product-details-color {
  color: #000000;
  background-color: #fff;
  border-radius: 5px;
}

.product-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 5px;
}

.product-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.product-sku {
  font-size: 12px;
  line-height: 16px;
  color: #495057;
  margin-top: 8px;
}

.status-span {
  font-size: 12px;
  line-height: 16px;
  color: #525252;
}

.quntity {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.pushed-date {
  color: #495057;
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

.disable-events {
  pointer-events: none;
}

.warning-text {
  color: black;
}

.push-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #ecffec !important;

  .chip-icon-color {
    color: #28a228 !important;
    font-size: 8.33px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    //  line-height: 19px;
    margin-top: 0 !important;
    color: #085f07;
    text-transform: capitalize;
  }
}

.partially-push-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #fff2e3 !important;

  .chip-icon-color {
    color: #aa5200 !important;
    font-size: 8.33px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 10px;
    margin-top: 0 !important;
    color: #aa5200;
    text-transform: capitalize;
  }
}

.not-push-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #fcb058 !important;

  .chip-icon-color {
    color: #495057 !important;
    font-size: 8.33px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    //line-height: 19px;
    margin-top: 0 !important;
    color: #495057;
    text-transform: capitalize;
  }
}

.failed-chip {
  height: 24px;
  border-radius: 10px;
  text-align: center !important;
  justify-content: center;
  background: #ffecec !important;

  .chip-icon-color {
    color: #ff0000 !important;
    font-size: 11px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 19px;
    margin-top: 0 !important;
    color: #ff0000;
    text-transform: capitalize;
  }
}

.disabled-push-button {
  background: #dcdcdc !important;
  color: #a8a8a8;
}

.customer-error-card {
  background-color: white;
  border-left: 12px solid red;
}

.alert-error {
  box-shadow: 2px 4px 10px 0px #0000001a !important;
  border-left: 12px solid rgba(255, 115, 0, 1) !important;
  background: #fff !important;
  padding: 20px;
  border-radius: 0;
  color: #000;

  h4,
  p {
    font-weight: 400 !important;
  }

  h4 {
    font-size: 15px;
  }

  p {
    font-size: 14px;
  }
}

.background-card-style {
  background-color: #cccccc;
  border-radius: 5px;
}

.per-width {
  width: 100%;
}

.separator-hr {
  margin-top: 15px;
  margin-bottom: 15px;
  height: 1px !important;
  width: 100%;
}

.tag-chip {
  background: #6eb3d0 !important;
  border-radius: 10px;
}

.notes {
  font-family: Circular Std, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  /* or 17px */

  letter-spacing: 0.2px;

  color: #212429;
}
</style>
