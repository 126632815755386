<template>
  <v-overlay v-if="showUpgradeDialog && currentShop">
    <v-card class="upgrade-plan-dialog pa-5" max-width="750" outlined light>
      <v-card-subtitle class="d-flex subtitle warning--text align-center pb-1">
        <v-icon class="mr-3 warning--text">error_outline</v-icon>
        THIS IS AN ADD-ON FEATURE
      </v-card-subtitle>

      <v-card-title class="pa-0 primary--text">
        <v-card-text>
          <h1 class="pl-1" style="line-height: 36px; font-size: 28px;">Easily keep track of sales and commissions from <br> synced orders</h1>
        </v-card-text>
      </v-card-title>

      <v-card-actions>
        <v-card-text class="pt-0 pb-0" style="line-height: 32px;">
          Payouts allows you to create, manage and share payment information with Source stores for simpler earnings settlements (Shopify only).
          <a class="learn-more" target="_blank" href="https://help.syncio.co/en/articles/6398970-payouts-add-on-destination-store-side">Learn More.</a>
        </v-card-text>
      </v-card-actions>

      <v-card-actions class="pb-1">
        <v-card-text>
          <v-btn elevation="0" class="btn btn-border lg" width="100px" @click="redirectBack()">Back</v-btn>
          <a class="float-right">
            <v-btn elevation="0" class="btn btn-border lg inverse" @click="redirectToPlanUpgradePage()">
              Upgrade Plan
            </v-btn>
          </a>
        </v-card-text>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "PayoutsUpgradeModule",
  computed: {
    ...mapState('shop', ['currentShop']),
    ...mapGetters('plan', ['isPayoutsModuleAvailable']),
    showUpgradeDialog() {
      return !this.isPayoutsModuleAvailable
    }
  },
  methods: {
    redirectBack() { this.$router.go(-1); },
    redirectToPlanUpgradePage() {
      this.$router.replace({ name: "PlanSelectionPage" });
    },
  }
}
</script>

<style lang="scss" scoped>
.upgrade-plan-dialog {
  margin: auto;

  .subtitle {
    font-weight: 700;
  }

  .learn-more {
    color: #1E88E5;
    font-weight: 600;
  }

  .upgrade-plan {
    padding: 10px 40px;
  }
}
</style>
