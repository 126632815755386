<script>
import InvoicedOrder from "@/views/payouts/components/InvoicedOrder.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "InvoicedOrdersTable",

  mixins: [payoutsMixin],

  data() {
    return {
      areProductsVisible: false,
    }
  },

  props: {
    isPreviewMode: {
      type: Boolean,
      required: true
    },
  },

  components: {
    InvoicedOrder
  },
}
</script>

<template>
  <section class="table selected-orders-table mt-5" v-if="payoutsPreview && payoutsPreview.length > 0">
    <v-simple-table class="fixed-layout">
      <template>
        <thead>
          <tr>
            <th style="width: 8%;"></th>
            <th style="width: 17%;" class="pl-0">My Order #</th>
            <th style="width: 15%;">Source Order</th>
            <th style="width: 8%;">Items</th>
            <th style="width: 15%;">Value</th>
            <th style="width: 16%;">Commission</th>
            <th style="width: 12%;">Payable</th>
            <th  style="width: 9%;" class="text-center">Actions</th>
          </tr>
        </thead>
        <InvoicedOrder v-for="payout in payoutsPreview" :key="payout.order_id" :payout="payout" :isPreviewMode="isPreviewMode" />
      </template>
    </v-simple-table>
  </section>
</template>
