<script>
import { ChevronDown, CalendarIcon, CloseCircle, DocumentIcon, IconFilter, PlusCircle, StoreLarge } from "@/icons";
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapState } from "vuex";
import DateRangeFilter from "@/views/payouts/components/DateRangeFilter.vue";
import OrderDetailsBlock from "@/views/order/components/OrderDetailsBlock";
import PageHeader from "@/views/payouts/components/PageHeader.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";
import SelectFilter from "@/views/payouts/components/SelectFilter.vue";
import Tabs from "@/views/payouts/components/Tabs.vue";
import ViewPayoutDialog from "@/views/payouts/components/ViewPayoutDialog.vue";
import Summary from "@/views/payouts/components/Summary.vue";
import PaidPayoutsCheckbox from "@/views/payouts/components/PaidPayoutsCheckbox.vue"
import Pagination from '@/components/UI/Pagination.vue'

export default {
  name: "Records",

  data() {
    return {
      filteredStoreOptions: [],
      isOrderFilterVisible: false,
      isPayoutStatusOptionsVisible: false,
      isStoreFilterVisible: false,
      ordersFilterOptions: [
        { key: 'orders_status', value: 'paid', label: 'Paid' },
        { key: 'orders_status', value: 'payment_confirmed', label: 'Received' },
      ],
      selectedPayoutStatus: "",
      storeSearchTerm: "",
    }
  },

  mixins: [payoutsMixin],

  created: async function() {
    if(!this.currentShop) {
      await this.$store.dispatch(`shop/init`).then(() => {
        if (this.currentShop != null) {
          this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        }
      });
    }

    this.filteredStoreOptions = [...this.filteredStoreOptions, ...this.storeFilterOptions];
    this.loadingRecords = true
    this.setDefaultStartAndEndDate();
    this.$store.dispatch("payouts/fetchDashboardStats", this.currentShop.id);
    await this.fetchPaidRecords();
    this.loadingRecords = false
  },

  computed: {
    ...mapState("shop", ["connectedShops"]),

    storeFilterOptions() {
      return this.connectedShops.map(shop => shop);
    },
  },

  components: {
    CalendarIcon,
    ChevronDown,
    CloseCircle,
    DateRangeFilter,
    DocumentIcon,
    IconFilter,
    OrderDetailsBlock,
    PageHeader,
    PlusCircle,
    SelectFilter,
    StoreLarge,
    Tabs,
    ViewPayoutDialog,
    Summary,
    PaidPayoutsCheckbox,
    Pagination,
  },

  methods: {
    async applyStoreFilter(filter) {
      this.isStoreFilterVisible = false;
      this.paidPayoutsFilters.target_store = filter;
      this.selectedRecordStore = filter;
      await this.fetchPaidRecords();
      this.paidRecordsCurrentPage = 1;
    },

    closeFilters() {
      this.isOrderFilterVisible = false;
      this.isStoreFilterVisible = false;
    },

    async applyFilter({ key, value }) {
      this.paidPayoutsFilters[key] = value;
      this.closeFilters();
      await this.fetchPaidRecords();
      this.paidRecordsCurrentPage = 1;
    },

    onClickOutsideStoreFilter() {
      this.isStoreFilterVisible = false;
    },

    onClickOutsideOrderFilter() {
      this.isOrderFilterVisible = false;
    },

    async onClearFiltersHandler() {
      Object.keys(this.paidPayoutsFilters).forEach(key => {
        this.paidPayoutsFilters[key] = "";
      });
      this.setDefaultStartAndEndDate();
      this.paidPayoutsFilters.orders_status = "paid_received";
      this.paidPayoutsFilters.date_range = "Last 30 days";
      this.paidPayoutsFilters.target_store = this.selectedRecordStore.text = "All Stores";
      await this.fetchPaidRecords(1);
    },

    onSearchStoreHandler(event) {
      this.filteredStoreOptions = this.storeFilterOptions.filter(store => store.store_domain.includes(event.target.value));
    },

    swapItems() {
      this.isSearchingStore = true;
      document.querySelector(".search-input").focus();
    },

    paginationHandler(page) {
      this.fetchPaidRecords(page);
    },

    async onDateRangeSelectedHandler(value) {
      const { label } = value;
      this.paidPayoutsFilters.date_range = label;
      this.dateRange.startDate = value.startDate;
      this.dateRange.endDate = value.endDate;
      await this.fetchPaidRecords();
      this.recordsCurrentPage = 1;
    },

    updateCurrentPageHandler(page) {
      this.paidRecordsCurrentPage = page
      this.fetchPaidRecords(this.paidRecordsCurrentPage)
    }
  }
}
</script>

<template>
  <section class="page payouts-page">
    <PayoutsUpgradeDialog />

    <!-- Page Header -->
    <PageHeader pageDescription="Manage Payouts for fulfilled orders">
      <template #title>Manage payouts</template>
    </PageHeader>

    <!-- Summary -->
    <Summary :summary="payoutsDashboard" />

    <!-- Tabs -->
    <Tabs />

    <!-- Filters -->
    <section class="filters-listing">
      <ul class="d-flex pa-0 justify-start">
        <li class="filters-listing__filter" data-filter="stores" @click.capture="isStoreFilterVisible = true" v-click-outside="onClickOutsideStoreFilter">
          <SelectFilter label="All Stores">
            <template #iconBefore>
              <StoreLarge />
            </template>
            <template #label>
              <div class="label" @click="swapItems">
                <input type="text" v-model="storeSearchTerm" v-show="isStoreFilterVisible" class="search-input" @keyup="onSearchStoreHandler">
                <span v-show="!isStoreFilterVisible">{{ paidPayoutsFilters.target_store.store_domain || paidPayoutsFilters.target_store }}</span>
              </div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <template #options>
              <transition name="slide-x-reverse-transition">
                <ul v-if="isStoreFilterVisible" class="filter-options on-left">
                  <li
                    :class="{ 'active': paidPayoutsFilters.target_store.id === filter.id }"
                    v-show="filteredStoreOptions.length > 0"
                    v-for="filter in filteredStoreOptions"
                    :key="filter.id"
                    @click.stop="applyStoreFilter(filter)">
                      {{ filter.store_domain }}
                  </li>
                  <li class="not-found" key="store-not-found" v-show="filteredStoreOptions.length === 0">Store not found</li>
                </ul>
              </transition>
            </template>
          </SelectFilter>
        </li>

        <li class="filters-listing__filter" data-filter="calendar">
          <SelectFilter>
            <template #iconBefore>
              <CalendarIcon />
            </template>
            <template #label>
              <div class="label">{{ paidPayoutsFilters.date_range || "Select Range"  }}</div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <DateRangeFilter opens="right" @onDateRangeSelected="onDateRangeSelectedHandler" :startDate="String(dateRange.startDate)" :endDate="String(dateRange.endDate)" />
          </SelectFilter>
        </li>

        <li class="filters-listing__filter" data-filter="orders" @click="isOrderFilterVisible = true" v-click-outside="onClickOutsideOrderFilter">
          <SelectFilter>
            <template #iconBefore>
              <IconFilter />
            </template>
            <template #label>
              <div class="label">Payment status
                <template v-if="paidPayoutsFilters.orders_status && paidPayoutsFilters.orders_status !== 'paid_received'">
                  <span v-if="paidPayoutsFilters.orders_status === 'payment_confirmed'">: Received</span>
                  <span v-else>: {{ paidPayoutsFilters.orders_status | removeUnderscore }}</span>
                </template>
              </div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute" style="height: 21px;">
                <PlusCircle />
              </div>
            </template>
            <template #options>
              <transition name="slide-x-reverse-transition">
                <ul class="filter-options" v-if="isOrderFilterVisible">
                  <li :class="{ 'active': paidPayoutsFilters.orders_status === filter.value }" v-for="filter in ordersFilterOptions" :key="filter.value" @click.stop="applyFilter(filter)">
                    {{ filter.label }}
                  </li>
                </ul>
              </transition>
            </template>
          </SelectFilter>
        </li>

        <li class="right-action-container">
          <button class="btn-icon d-flex align-center clear-filters-btn" @click="onClearFiltersHandler">
            <CloseCircle /> <span class="btn-icon--text">Clear Filters</span>
          </button>
        </li>
      </ul>
    </section>

    <div class="d-flex align-center my-4" v-if="bulkPaidPayouts.length > 0">
      <h4 class="mr-4" style="font-size: 16px;">{{ bulkPaidPayouts.length }} {{ bulkPaidPayouts.length > 1 ? 'payouts' : 'payout' }} selected</h4>
      <v-btn elevation="0" class="btn btn-border inverse lg" @click="markAsUnPaidBulkHandler">Mark as Unpaid</v-btn>
    </div>

    <!-- Payable Orders Table -->
    <section class="table fixed-layout">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 3%;"></th>
              <th style="width: 12%;">Date (AEST)</th>
              <th style="width: 7.5%;">Payout ID</th>
              <th style="width: 22.5%;">Store</th>
              <th style="width: 10%">Amount</th>
              <th style="width: 10%;">Payment Status</th>
              <th style="width: 35%; text-align: right;">Actions</th>
            </tr>
          </thead>
          <tbody v-if="paidRecords.length === 0 && !loadingPayoutRecords">
            <tr class="no-hover">
              <td colspan="7" class="text-center empty-table-message">
                <p><strong>You have no Paid payouts to show at this time</strong></p>
                <p class="mb-0 v2 pb-0">
                  <a href="https://help.syncio.co/en/articles/6398970-payouts-add-on-destination-store-side" target="_blank" class="link link-underline">
                    Learn more about payouts
                    <IconNewTab />
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="record in paidRecords" :key="record.payout_id" :class="{ 'selected': bulkPaidPayouts.includes(record.payout_id) }">
              <td>
                <PaidPayoutsCheckbox :payout="record" v-if="record.status === 'paid'" />
              </td>
              <td>{{ record.date }}</td>
              <td>{{ record.payout_id }}</td>
              <td>{{ record.store_name }}</td>
              <td class="tabular-fonts"><span v-if="record.payout_total < 0">-$</span>{{ record.payout_total | currencyFormatter(currency) }}</td>
              <td>
                <span v-if="record.status === 'payment_confirmed'" class="status paid">Received</span>
                <span v-else class="status" :class="record.status">{{ record.status | removeUnderscore }}</span>
              </td>
              <td style="text-align: right;">
                <v-btn elevation="0" class="btn btn-border" @click="viewPayoutHandler(record, 'destination')" :disabled="isViewPayoutRecordLoading">
                  <DocumentIcon />
                  <span v-if="record.status === 'payment_confirmed'" class="ml-3">View Payout</span>
                  <span v-else class="ml-3">Manage Payout</span>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </section>

    <!-- Pagination -->
    <Pagination :pagination="paidRecordsPagination" @updateCurrentPage="updateCurrentPageHandler" class="v2 bt-0" />

    <!-- View Payout Dialog -->
    <ViewPayoutDialog v-if="isViewPayoutDialogVisible" />

    <!-- Order Details -->
    <v-dialog v-model="isOrderDetailsVisible" transition="slide-x-reverse-transition" light content-class="offscreen-dialog">
      <OrderDetailsBlock />
    </v-dialog>

  </section>
</template>
