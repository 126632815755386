<script>
import axios from 'axios';
import deepmerge from "deepmerge";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";
var Nanobar = require('../../../../node_modules/nanobar/nanobar');
let nanobar = new Nanobar();

export default {
  name: "PayoutEvents",

  mixins: [payoutsMixin],

  props: {
    events: {
      type: Object,
      required: true
    }
  },

  methods: {
    async loadMoreEventsHandler() {
      try {
        nanobar.go(this.randomNumber(20, 90));
        const response = await axios.post(this.events.next_page_url, {
          current_store_id: this.currentShop.id,
          payout_id: this.selectedRecordId,
        });
        nanobar.go(100);

        const { next_page_url, events } = response.data;
        this.events.next_page_url = next_page_url;
        this.events.events = deepmerge(this.events.events, events);
      } catch(error) {
        nanobar.go(100);
      }
    }
  },
}
</script>

<template>
  <section class="table payouts-table mt-5">
    <v-simple-table class="fixed-layout" data-section="events">
      <template>
        <thead>
          <tr>
            <th style="width: 20%">Date created (AEST)</th>
            <th style="width: 17.5%">Type</th>
            <th style="width: 22.5%">Store</th>
            <th style="width: 40%">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in events.events" :key="event.id">
            <td>{{ event.created_at | humanReadableDateTime }}</td>
            <td>
              <span v-if="event.type === 'comment'">Comment</span>
              <span v-else>Event</span>
            </td>
            <td>{{ event.actor }}</td>
            <td>{{ event.body }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <button class="btn btn-border lg mt-10" v-if="events.next_page_url" @click="loadMoreEventsHandler">Load More Events</button>
  </section>
</template>
