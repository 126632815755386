import { createHelpers } from 'vuex-map-fields';
import { mapGetters } from 'vuex';
import dateRangeMixin from "@/views/payouts/mixin/dateRangeMixin";
import moment from "moment";
var Nanobar = require('../../../../node_modules/nanobar/nanobar');
const nanobar = new Nanobar();
import PayoutsUpgradeDialog from "@/views/payouts/components/PayoutsUpgradeDialog.vue";

const { mapFields } = createHelpers({
  getterType: 'payouts/getField',
  mutationType: 'payouts/updateField'
});

export default {
  data() {
    return {
      isAddingToInvoicedOrdersList: false,
    }
  },

  mixins: [dateRangeMixin],

  components: {
    PayoutsUpgradeDialog
  },

  computed: {
    ...mapFields([
      "currency",
      "comment",
      "dashboardStats",
      "filters",
      "isAddOrdersToInvoiceVisible",
      "isOrderDetailsVisible",
      "isPayoutCreated",
      "isPayoutDialogVisible",
      "isStatusUpdating",
      "isViewingPayableOrders",
      "isViewPayoutDialogVisible",
      "isViewPayoutRecordLoading",
      "limiter",
      "loadindDashboardStats",
      "loadingPayableOrders",
      "loadingPayoutRecords",
      "loadingPayouts",
      "payableOrderCurrentPage",
      "payableOrders",
      "payableOrdersFilters",
      "payoutDetails",
      "payouts",
      "payoutsCurrentPage",
      "payoutsDashboard",
      "payoutsFilters",
      "payoutsPagination",
      "payoutsPreview",
      "payoutStatusOptions",
      "preview",
      "records",
      "recordsCurrentPage",
      "recordsPagination",
      "searchTerm",
      "selectedPayoutOrders",
      "selectedRecord",
      "selectedRecordId",
      "selectedRecordStore",
      "selectedStore",
      "sourcePayouts",
      "sourcePayoutsCurrentPage",
      "sourcePayoutsFilters",
      "timeZone",
      "totalSourcePayouts",
      "bulkSourcePayouts",

      "bulkPaidPayouts",
      "bulkUnpaidPayouts",
      "paidPayoutsFilters",
      "paidRecords",
      "paidRecordsCurrentPage",
      "paidRecordsPagination",
      "selectedPaidRecordStore",
      "sourceCompletePayouts",
      "sourceCompletePayoutsFilters",
      "sourceCompletePayoutsCurrentPage",
      "lineItems",
      "payoutTotal",
    ]),

    ...mapGetters("shop", ["currentShop"]),
  },

  methods: {
    /* Helper Methods */
    arrayToObject (array, keyField) {
      return array.reduce((obj, item) => {
        obj[item[keyField]] = item
        return obj
      }, {})
    },

    formatToPrice(value) {
      return `$${value.toFixed(2)}`;
    },

    randomNumber(min, max){
      const r = Math.random()*(max-min) + min
      return Math.floor(r)
    },

    setPayoutFilters() {
      let dateRange = "";
      this.filters.forEach(filter => {
        if(Object.prototype.hasOwnProperty.call(filter, "date_range")) {
          dateRange = filter.date_range;
        }
      });

      this.filters = [];
      const { orders_status, target_store } = this.payableOrdersFilters;
      if(target_store !== "All Stores") {
        this.filters.push({ target_store: target_store });
      }

      this.filters.push({ status: orders_status });

      if(this.searchTerm !== "") {
        this.filters.push({ order_number: this.searchTerm });
      }

      const { endDate, startDate } = this.dateRange;
      let formattedStartDate = moment(startDate || dateRange.split("to")[0].trim()).format("YYYY-MM-DD");
      let formattedEndDate = moment(endDate || dateRange.split("to")[1].trim()).format("YYYY-MM-DD");
      this.filters.push({ date_range: `${formattedStartDate} to ${formattedEndDate}` });
    },

    async fetchPayableOrdersListner() {
      await this.$store.dispatch("payouts/fetchPayableOrders", {
        storeId: this.currentShop.id,
        filters: this.filters,
      });
    },

    async fetchPayableOrders() {
      try {
        this.loadingPayableOrders = true;
        nanobar.go(this.randomNumber(20, 90));
        this.setPayoutFilters();
        await this.fetchPayableOrdersListner();
        this.loadingPayableOrders = false;
        nanobar.go(100);
      } catch(error) {
        nanobar.go(100);
        this.loadingPayableOrders = false;
      }
    },

    async fetchPayoutsListner(targerStoreId, page) {
      await this.$store.dispatch("payouts/fetchPayouts", {
        current_store_id: this.currentShop.id,
        filters: this.filters,
        limiter: this.limiter,
        target_store_id: +targerStoreId,
        page
      });
    },

    async fetchPayouts(targerStoreId, targetStore, page) {
      try {
        nanobar.go(this.randomNumber(20, 90));
        this.setPayoutFilters();
        await this.fetchPayoutsListner(targerStoreId, page);
        this.isViewingPayableOrders = false;
        this.payableOrdersFilters.target_store = targerStoreId;
        this.selectedStore.text = targetStore?.store_name || this.selectedStore.text;
        nanobar.go(100);
      } catch (error) {
        nanobar.go(100);
      }
    },

    calculateDashboardStats() {
      const initialStats = {
        commission: 0,
        total: 0,
        taxes: 0,
        orders_count: 0,
        sales: 0
      };

      const updatedStats = this.selectedPayoutOrders.reduce((stats, payout) => {
        const commission = payout.commission ? +payout.commission : 0;
        const total = payout.payout_amount ? +payout.payout_amount : 0;
        const taxes = payout.tax ? +payout.tax : 0;
        const ordersCount = payout.total_products ? +payout.total_products : 0;
        const sales = payout.sales ? +payout.sales : 0;

        return {
          commission: stats.commission + commission,
          total: stats.total + total,
          taxes: stats.taxes + taxes,
          orders_count: stats.orders_count + ordersCount,
          sales: stats.sales + sales
        };
      }, initialStats);

      Object.assign(this.payoutsDashboard, updatedStats);
    },

    async fetchPayoutPreviewListner(payoutIds) {
      await this.$store.dispatch("payouts/fetchPayoutPreview", {
        current_store_id: +this.currentShop.id,
        order_ids: payoutIds,
        target_store_id: +this.payableOrdersFilters.target_store,
      });
    },

    async addPayoutToInvoicedOrders(payout) {
      try {
        const { payoutIds } = this.payoutsDashboard;
        if(payoutIds.includes(payout.order_id)) { return }

        this.loadindDashboardStats = true;
        nanobar.go(this.randomNumber(20, 90));
        this.isAddingToInvoicedOrdersList = true;
        this.payoutsDashboard.payoutIds = [...payoutIds, payout.order_id];
        await this.fetchPayoutPreviewListner(this.payoutsDashboard.payoutIds);
        this.selectedPayoutOrders = [...this.selectedPayoutOrders, payout];
        this.calculateDashboardStats();
        this.isAddingToInvoicedOrdersList = false;
        this.getFinalPayoutValue()
        nanobar.go(100);
        this.loadindDashboardStats = false;
      } catch {
        nanobar.go(100);
        this.loadindDashboardStats = false;
      }
    },

    removePayoutFromInvoicedOrders(payout) {
      this.isAddingToInvoicedOrdersList = true;
      const PAYOUT_ID_TO_REMOVE = this.payoutsDashboard.payoutIds.find(item => +item === +payout.order_id);
      const PAYOUT_ID_INDEX = this.payoutsDashboard.payoutIds.findIndex(item => +item === +PAYOUT_ID_TO_REMOVE);
      const SELECTED_PAYOUTS_INDEX = this.selectedPayoutOrders.findIndex(item => +item.order_id === +PAYOUT_ID_TO_REMOVE);
      const PAYOUTS_PREVIEW_INDEX = this.payoutsPreview.findIndex(item => +item.order_id === +PAYOUT_ID_TO_REMOVE);
      this.payoutsDashboard.payoutIds.splice(PAYOUT_ID_INDEX, 1);
      this.selectedPayoutOrders.splice(SELECTED_PAYOUTS_INDEX, 1);
      this.payoutsPreview.splice(PAYOUTS_PREVIEW_INDEX, 1);
      this.calculateDashboardStats();
      this.isAddingToInvoicedOrdersList = false;
      this.getFinalPayoutValue()
    },

    async selectPayoutsFromCheckboxHandler($event, payout) {
      $event ? this.addPayoutToInvoicedOrders(payout) : this.removePayoutFromInvoicedOrders(payout);
    },

    async updatePayoutStatusHandler() {
      nanobar.go(this.randomNumber(20, 90));
      this.isStatusUpdating = true;
      await this.$store.dispatch("payouts/updatePayout", {
        current_store_id: this.currentShop.id,
        payout_id: +this.selectedRecordId,
        status: this.selectedPayoutStatus,
      });

      await this.$store.dispatch("payouts/fetchPayout", {
        current_store_id: this.currentShop.id,
        payout_id: this.selectedRecordId,
        target_store_id: this.payoutDetails.source_store_id,
      });

      const { name } = this.$route
      if(name === 'UnpaidPayouts') {
        this.$router.push({ name: 'PaidPayouts' })
        this.bulkUnpaidPayouts = []
      } else if(name === 'PaidPayouts') {
        this.$router.push({ name: 'UnpaidPayouts' })
        this.bulkPaidPayouts = []
      }

      this.isPayoutStatusOptionsVisible = false;
      this.isStatusUpdating = false;
      this.isCancelDialogVisible = false;
      this.isViewPayoutDialogVisible = false;
      nanobar.go(100);
    },

    async markAsPaidHandler(payoutId) {
      this.selectedRecordId = payoutId
      nanobar.go(this.randomNumber(20, 90));
      const { data } = await this.$store.dispatch("payouts/updatePayout", {
        current_store_id: this.currentShop.id,
        payout_id: +this.selectedRecordId,
        status: 'paid',
      });

      if(!data.success) return

      this.bulkUnpaidPayouts = []
      this.$router.push({ name: 'PaidPayouts' })
    },

    async markAsPaidBulkHandler() {
      const { data } = await this.$store.dispatch("payouts/updatePayout", {
        current_store_id: this.currentShop.id,
        payout_id: this.bulkUnpaidPayouts,
        status: 'paid',
      });

      if(!data.success) return
      this.$router.push({ name: 'PaidPayouts' })
      this.bulkUnpaidPayouts = []
    },

    async markAsUnPaidBulkHandler() {
      const { data } = await this.$store.dispatch("payouts/updatePayout", {
        current_store_id: this.currentShop.id,
        payout_id: this.bulkPaidPayouts,
        status: 'unpaid',
      });

      if(!data.success) return
      this.$router.push({ name: 'UnpaidPayouts' })
      this.bulkPaidPayouts = []
    },

    async viewPayoutListner(record, storeType) {
      const { payout_id, target_store_id } = record;

      if(storeType === 'destination') {
        await this.$store.dispatch("payouts/fetchPayout", {
          current_store_id: this.currentShop.id,
          payout_id,
          target_store_id: +target_store_id,
        });
      } else {
        await this.$store.dispatch("payouts/fetchPayout", {
          current_store_id: +target_store_id,
          payout_id,
          target_store_id: this.currentShop.id,
        });
      }
    },

    async viewPayoutHandler(record, storeType) {
      try {
        nanobar.go(this.randomNumber(20, 90));
        const { payout_id, store_name } = record;
        this.isViewPayoutRecordLoading = true;
        await this.viewPayoutListner(record, storeType);
        this.selectedRecordId = payout_id;
        this.selectedRecord = store_name;
        this.isViewPayoutDialogVisible = true;
        nanobar.go(100);
        this.isViewPayoutRecordLoading = false;
      } catch (error) {
        nanobar.go(100);
        this.isViewPayoutRecordLoading = false;
      }
    },

    async fetchRecordsListner(filters, page) {
      await this.$store.dispatch("payouts/fetchRecords", {
        filters,
        limiter: this.limiter,
        page,
        storeId: this.currentShop.id,
      });
    },

    async fetchPaidRecordsListner(filters, page) {
      await this.$store.dispatch("payouts/fetchPaidRecords", {
        filters,
        limiter: this.limiter,
        page,
        storeId: this.currentShop.id,
      });
    },

    ranges() {
      let currentDateTime = new Date().toLocaleString("en-US", { "timeZone": this.timeZone });

      let startOfToday = null, endOfToday = null;
      startOfToday = this.setDateAndTime(startOfToday, currentDateTime, 0, true);
      endOfToday = this.setDateAndTime(endOfToday, currentDateTime, 0, false);

      let startOfYesterday = null, endOfYesterday = null;
      startOfYesterday = this.setDateAndTime(startOfYesterday, currentDateTime, 1, true);
      endOfYesterday = this.setDateAndTime(endOfYesterday, currentDateTime, 1, false);

      let startOfLastSevenDays = null;
      startOfLastSevenDays = this.setDateAndTime(startOfLastSevenDays, currentDateTime, 7, true);

      let startOfLastThirthDays = null;
      startOfLastThirthDays = this.setDateAndTime(startOfLastThirthDays, currentDateTime, 30, true);

      let startOfLastNintyDays = null;
      startOfLastNintyDays = this.setDateAndTime(startOfLastNintyDays, currentDateTime, 90, true);

      return {
        "Today": [startOfToday, endOfToday],
        "Yesterday": [startOfYesterday, endOfYesterday],
        "Last 7 Days": [startOfLastSevenDays, endOfToday],
        "Last 30 Days": [startOfLastThirthDays, endOfToday],
        "Last 90 Days": [startOfLastNintyDays, endOfToday],
      };
    },

    async fetchRecords(page) {
      try {
        this.loadingPayoutRecords = true;
        nanobar.go(this.randomNumber(20, 90));
        let filters = [];
        if(this.payoutsFilters.target_store !== "All Stores") {
          filters.push({ target_store: this.payoutsFilters.target_store.id });
        }
        filters.push({ status: this.payoutsFilters.orders_status });

        let ranges = this.ranges()
        let formattedStartDate = ''
        let formattedEndDate = ''
        let date_range = this.payoutsFilters.date_range

        Object.keys(ranges).forEach(key => {
          if(key.toLowerCase() === date_range.toLowerCase()) {
            formattedStartDate = moment(ranges[key][0]).format("YYYY-MM-DD");
            formattedEndDate = moment(ranges[key][1]).format("YYYY-MM-DD");
          }
        })

        filters.push({ date_range: `${formattedStartDate} to ${formattedEndDate}` });
        await this.fetchRecordsListner(filters, page);
        nanobar.go(100);
        this.loadingPayoutRecords = false;
      } catch(error) {
        nanobar.go(100);
        this.loadingPayoutRecords = false;
      }
    },

    async fetchPaidRecords(page) {
      try {
        this.loadingPayoutRecords = true;
        nanobar.go(this.randomNumber(20, 90));
        let filters = [];
        if(this.paidPayoutsFilters.target_store !== "All Stores") {
          filters.push({ target_store: this.paidPayoutsFilters.target_store.id });
        }
        filters.push({ status: this.paidPayoutsFilters.orders_status });

        let ranges = this.ranges()
        let formattedStartDate = ''
        let formattedEndDate = ''
        let date_range = this.paidPayoutsFilters.date_range

        Object.keys(ranges).forEach(key => {
          if(key.toLowerCase() === date_range.toLowerCase()) {
            formattedStartDate = moment(ranges[key][0]).format("YYYY-MM-DD");
            formattedEndDate = moment(ranges[key][1]).format("YYYY-MM-DD");
          }
        })

        filters.push({ date_range: `${formattedStartDate} to ${formattedEndDate}` });
        await this.fetchPaidRecordsListner(filters, page);
        nanobar.go(100);
        this.loadingPayoutRecords = false;
      } catch(error) {
        nanobar.go(100);
        this.loadingPayoutRecords = false;
      }
    },

    async selectBulkUnPaidPayouts($event, { payout_id }) {
      if($event) {
        this.bulkUnpaidPayouts = [...this.bulkUnpaidPayouts, payout_id]
      } else {
        const PAYOUT_ID_TO_REMOVE = this.bulkUnpaidPayouts.findIndex(item => +item === +payout_id);
        this.bulkUnpaidPayouts.splice(PAYOUT_ID_TO_REMOVE, 1);
      }
    },

    async selectBulkPaidPayouts($event, { payout_id }) {
      if($event) {
        this.bulkPaidPayouts = [...this.bulkPaidPayouts, payout_id]
      } else {
        const PAYOUT_ID_TO_REMOVE = this.bulkPaidPayouts.findIndex(item => +item === +payout_id);
        this.bulkPaidPayouts.splice(PAYOUT_ID_TO_REMOVE, 1);
      }
    },

    async selectBulkSourcePayouts($event, { payout_id }) {
      if($event) {
        this.bulkSourcePayouts = [...this.bulkSourcePayouts, payout_id]
      } else {
        const PAYOUT_ID_TO_REMOVE = this.bulkSourcePayouts.findIndex(item => +item === +payout_id);
        this.bulkSourcePayouts.splice(PAYOUT_ID_TO_REMOVE, 1);
      }
    },

    getFinalPayoutValue() {
      const initialValue = 0;
      const lineItemsSum = this.lineItems.reduce((accumulator, currentValue) => accumulator + Number(currentValue.amount), initialValue);
      this.payoutTotal = this.payoutsDashboard.total + Number(Number(lineItemsSum).toFixed(2))
    },

    scrollToOrdersSection() {
      const invoicedOrders = document.querySelector('[data-section="invoiced-orders"]')
      invoicedOrders.scrollIntoView({ behavior: 'smooth' })
      // setTimeout(() => {
      //   const expandButton = document.querySelectorAll('.push-bottom')
      //   expandButton[0].click()
      // }, 500)
    }
  }
}
