<script>
import { ArrowBack, CloseCircle } from "@/icons";
import { FETCH_PUSH_SETTINGS } from "@/store/actions.type";
import Events from "@/views/payouts/components/Events.vue";
import InvoicedOrdersTable from "@/views/payouts/components/InvoicedOrdersTable.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "ViewPayout",

  mixins: [payoutsMixin],

  data() {
    return {
      eventType: '',
      isCancelDialogVisible: false,
      isConfirmDialogVisible: false,
      isPayoutStatusOptionsVisible: false,
      loadingEvents: false,
      payoutEvents: {},
      selectedPayoutStatus: "",
    }
  },

  async mounted() {
    this.fetchPayoutEvents();
    this.setPayoutStatus();
    await this.$store.dispatch(`orders/${FETCH_PUSH_SETTINGS}`, {
      storeId: this.currentShop.id
    });
  },

  computed: {
    isPayoutCancelled() {
      return this.payoutDetails.status === "cancelled";
    },

    isPaymentConfirmed() {
      return this.payoutDetails.status === "payment_confirmed";
    },

    lineItemCount() {
      return this.payoutsPreview.reduce((lineItemCount, payout) => lineItemCount + +payout.line_item_count, 0);
    },

    disableUpdateStatusButton() {
      return this.payoutDetails.status === this.selectedPayoutStatus || (this.payoutDetails.status === "payout_created" && this.selectedPayoutStatus === "unpaid") || this.isStatusUpdating;
    }
  },

  components: {
    ArrowBack,
    CloseCircle,
    Events,
    InvoicedOrdersTable,
  },

  watch: {
    'payoutDetails'(details) {
      switch (details.status) {
        case "payout_created":
          this.selectedPayoutStatus = "unpaid";
          break;

        default:
          this.selectedPayoutStatus = details.status;
          break;
      }

      this.fetchPayoutEvents();
    }
  },

  methods: {
    setPayoutStatus() {
      if(this.payoutDetails.status === 'payout_created') {
        this.selectedPayoutStatus = "unpaid"
      } else {
        this.selectedPayoutStatus = this.payoutDetails.status;
      }
    },

    onClickOutsidePayoutStatusOptions() {
      this.isPayoutStatusOptionsVisible = false;
      this.setPayoutStatus();
    },

    async fetchPayoutEvents() {
      const response = await this.$store.dispatch("payouts/fetchPayoutEvents", {
        current_store_id: this.currentShop.id,
        payout_id: this.selectedRecordId,
      });

      this.payoutEvents = response.data;
    },

    async addCommentHandler() {
      this.loadingEvents = true
      const response = await this.$store.dispatch("payouts/createComment", {
        comment: this.comment,
        current_store_id: this.currentShop.id,
        payout_id: this.payoutDetails.id
      })

      await this.fetchPayoutEvents()
      this.comment = null
      this.loadingEvents = false
    },

    closeCancelDialogHandler() {
      this.isCancelDialogVisible = false;
    },

    async cancelPayoutHandler() {
      await this.$store.dispatch("payouts/cancelPayout", {
        payout_id: this.selectedRecordId,
        current_store_id: this.currentShop.id
      });

      await this.fetchRecords(1);
      this.isCancelDialogVisible = false;
      this.isViewPayoutDialogVisible = false;
    },

    async deletePayoutHandler() {
      await this.$store.dispatch("payouts/deletePayout", this.selectedRecordId);

      const { name } = this.$route
      if(name === 'UnpaidPayouts') {
        await this.fetchRecords(1);
      } else if(name === 'PaidPayouts') {
        await this.fetchPaidRecords(1);
      }

      this.isCancelDialogVisible = false;
      this.isViewPayoutDialogVisible = false;
    },

    showCancelOrDeleteDialog(type) {
      this.eventType = type;
      this.isCancelDialogVisible = true;
    },

    async paymentReceivedHandler() {
      await this.$store.dispatch("payouts/confirmPayout", {
        current_store_id: this.currentShop.id,
        payout_id: this.selectedRecordId
      });

      this.isCancelDialogVisible = false;
      this.isViewPayoutDialogVisible = false;
      this.$router.push({ name: 'CompleteSourcePayouts' })
    },
  }
}
</script>

<template>
  <v-dialog v-model="isViewPayoutDialogVisible" transition="slide-x-reverse-transition" light content-class="offscreen-dialog">
    <v-card elevation="0" class="payouts">

      <!-- Details -->
      <transition name="slide-x-reverse-transition" mode="out-in">
        <div key="1" v-if="!isCancelDialogVisible && !isConfirmDialogVisible">
          <aside class="payouts-container payouts__details">
            <h2 class="payouts__heading">
              Payout {{ payoutDetails.id }}
              <span class="close-modal-icon" @click="isViewPayoutDialogVisible = false">
                <CloseCircle />
              </span>
            </h2>

            <div class="payouts__breakdown" style="display: block; margin-right: 175px;">

              <div class="mt-6 payouts__breakdown-details first">
                <span class="title">Created on</span>
                <span class="title-data">{{ new Date(payoutDetails.updated_at).toLocaleString("en-US", { timeZone: "Australia/Sydney" }) | moment("DD MMMM, YYYY") }}</span>
              </div>

              <div class="payouts__breakdown-details">
                <span class="title">Send to</span>
                <span class="title-data">{{ selectedRecord || selectedStore.text }}</span>
              </div>

              <div class="payouts__breakdown-details second-last">
                <span class="title">Amount</span>
                <table class="title-data" cellspacing="0">
                  <thead>
                    <tr>
                      <th style="width: 35%;">Item</th>
                      <th style="width: 45%;">Description</th>
                      <th style="width: 20%;" class="text-right">Amount($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sales value</td>
                      <td>Subtotal</td>
                      <td class="text-right tabular-fonts">{{ payoutDetails.sale_total | currencyFormatter(currency, true) }}</td>
                    </tr>
                    <tr>
                      <td>Commission</td>
                      <td>
                        <a v-if="!isPayoutCancelled" href="javascript:void(0);" @click="scrollToOrdersSection" class="link link-underline">See orders invoiced</a>
                      </td>
                      <td class="text-right tabular-fonts">-{{ payoutDetails.commission_total | currencyFormatter(currency, true) }}</td>
                    </tr>
                    <tr v-for="(lineItem, index) in payoutDetails.payout_line_items" :key="index" class="line-items">
                      <td style="padding-top: 12px; padding-bottom: 12px;">
                        {{ lineItem.name }}
                      </td>
                      <td style="padding-top: 12px; padding-bottom: 12px;">
                        {{ lineItem.description }}
                      </td>
                      <td class="text-right tabular-fonts" style="padding-top: 12px; padding-bottom: 12px;">
                        <span v-if="lineItem.amount < 0">-</span>{{ lineItem.amount | currencyFormatter(currency, true) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" style="padding-top: 10px; padding-bottom: 0;"></td>
                    </tr>
                    <tr class="amount-due">
                      <td></td>
                      <td>Amount due</td>
                      <td class="text-right tabular-fonts">{{ payoutDetails.payout_total | currencyFormatter(currency, true) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="payouts__breakdown-details last mt-8">
                <button v-if="!isPaymentConfirmed && currentShop.type === 'destination'" :disabled="isPayoutStatusOptionsVisible" class="btn btn-border btn-delete lg" @click="showCancelOrDeleteDialog('delete')">Delete</button>
                <span class="title-data text-right">
                  <!-- <button v-if="(!isPayoutCancelled && !isPaymentConfirmed) && currentShop && currentShop.type === 'destination'" :disabled="isPayoutStatusOptionsVisible" class="btn btn-border lg" @click="showCancelOrDeleteDialog('cancel')">Cancel payout</button> -->
                  <div class="change-status ml-3" @click.stop="isPayoutStatusOptionsVisible = !isPayoutStatusOptionsVisible" v-if="!isPayoutCancelled && !isPaymentConfirmed && currentShop.type === 'destination'">
                    Mark as
                    <span class="icon">
                      <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 13.3441C10.9138 13.3445 10.8283 13.3277 10.7487 13.2946C10.6691 13.2616 10.5968 13.2129 10.5363 13.1516L6.16125 8.77657C6.074 8.64891 6.03462 8.49454 6.05007 8.34068C6.06552 8.18682 6.1348 8.04336 6.2457 7.9356C6.3566 7.82784 6.50199 7.7627 6.65623 7.75167C6.81047 7.74064 6.96365 7.78444 7.08876 7.87532L11 11.7603L14.9113 7.87532C15.0346 7.79536 15.1812 7.75911 15.3276 7.7724C15.474 7.78569 15.6117 7.84774 15.7186 7.94861C15.8255 8.04948 15.8955 8.18333 15.9173 8.3287C15.9391 8.47407 15.9114 8.62254 15.8388 8.75032L11.4638 13.1253C11.4054 13.1914 11.3342 13.2449 11.2545 13.2826C11.1747 13.3202 11.0881 13.3411 11 13.3441Z" fill="white"/>
                      </svg>
                    </span>
                    <transition name="slide-x-reverse-transition">
                      <ul class="payout-status-list" v-if="isPayoutStatusOptionsVisible" v-click-outside="onClickOutsidePayoutStatusOptions">
                        <li class="status-option" v-for="status in payoutStatusOptions" :key="status" @click.stop="selectedPayoutStatus = status" :class="{ 'active': status === selectedPayoutStatus }">{{ status }}</li>
                        <li class="status-button">
                          <button :disabled="disableUpdateStatusButton" class="btn btn-border lg" @click.stop="updatePayoutStatusHandler()">Update Status</button>
                        </li>
                      </ul>
                    </transition>
                  </div>
                </span>
                <v-btn @click="paymentReceivedHandler" elevation="0" class="btn btn-border btn-success lg ml-3" v-if="!isPaymentConfirmed && !isPayoutCancelled && currentShop.type === 'source'">
                  <svg width="21" height="21" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1">
                    <path d="M9.49992 18.2501C9.39921 18.2467 9.30025 18.2228 9.20911 18.1798C9.11798 18.1368 9.03658 18.0757 8.96992 18.0001L3.96992 13.0001C3.87458 12.9383 3.79454 12.8556 3.73583 12.7584C3.67712 12.6611 3.64127 12.5517 3.631 12.4386C3.62073 12.3254 3.6363 12.2114 3.67654 12.1052C3.71677 11.9989 3.78062 11.9032 3.86326 11.8252C3.94591 11.7473 4.04518 11.6891 4.1536 11.6551C4.26201 11.6211 4.37673 11.6122 4.48909 11.6291C4.60145 11.6459 4.70852 11.6881 4.80221 11.7523C4.8959 11.8166 4.97376 11.9013 5.02992 12.0001L9.49992 16.4401L19.9699 6.00009C20.1109 5.9087 20.2784 5.86728 20.4457 5.88247C20.613 5.89765 20.7704 5.96857 20.8926 6.08385C21.0148 6.19913 21.0947 6.3521 21.1196 6.51823C21.1445 6.68437 21.1129 6.85405 21.0299 7.00009L10.0299 18.0001C9.96325 18.0757 9.88185 18.1368 9.79072 18.1798C9.69958 18.2228 9.60062 18.2467 9.49992 18.2501Z" fill="#fff"/>
                  </svg>
                  <span class="ml-2">Payment received</span>
                </v-btn>
              </div>
            </div>
          </aside>

          <!-- Invoiced Orders -->
          <aside class="payouts-container payouts__orders" v-if="!isPayoutCancelled" data-section="invoiced-orders">
            <h2 class="payouts__heading">Orders Invoiced</h2>
            <p class="text-secondary mt-1 mb-4" v-if="!isPayoutCancelled && payoutsPreview.length > 0">{{ lineItemCount }} {{ lineItemCount > 1 ? "products" : "product" }} from {{ payoutsPreview.length }} {{ payoutsPreview.length > 1 ? "Orders" : "Order" }}</p>
            <InvoicedOrdersTable :isPreviewMode="false" />
          </aside>

          <!-- Events -->
          <aside class="payouts-container payouts__orders">
            <h2 class="payouts__heading">Comments and events</h2>

            <section class="comments pt-6 pb-3">
              <v-row>
                <v-col cols="7">
                  <v-text-field
                    v-model="comment"
                    solo
                    light
                    placeholder="Enter comments"
                    hint="Comments are visible to both stores"
                    persistent-hint />
                </v-col>
                <v-col cols="5">
                  <v-btn class="btn btn-border inverse lg" :loading="loadingEvents" :disabled="!comment" @click="addCommentHandler">Add comment</v-btn>
                </v-col>
              </v-row>
            </section>

            <Events :events="payoutEvents" />
          </aside>
        </div>

        <aside class="payouts-container payouts__details" key="2" v-else>
          <h2 class="payouts__heading mb-2">
            Payout {{ payoutDetails.id }}
            <button class="btn-icon d-flex align-center go-back-button" @click="isCancelDialogVisible = false">
              <ArrowBack /> <span class="btn-icon--text">Go Back</span>
            </button>
          </h2>

          <div class="payouts__breakdown" style="display: block; margin-right: 175px;">

            <div class="mt-6 payouts__breakdown-details first">
              <span class="title">Created on</span>
              <span class="title-data">{{ new Date(payoutDetails.updated_at).toLocaleString("en-US", { timeZone: "Australia/Sydney" }) | moment("DD MMMM, YYYY") }}</span>
            </div>

            <div class="payouts__breakdown-details">
              <span class="title">Send to</span>
              <span class="title-data">{{ selectedRecord || selectedStore.text }}</span>
            </div>

            <div class="payouts__breakdown-details">
              <span class="title">Amount due</span>
              <span class="title-data">{{ payoutDetails.payout_total | currencyFormatter(currency) }}</span>
            </div>

            <div class="payouts__breakdown-details last mt-6" style="border-top: 0">
              <span class="title">All orders in this payout will be made available again to the payout.</span>
              <span class="title-data text-right">
                <button class="btn btn-border lg" @click="closeCancelDialogHandler">Go back</button>
                <template v-if="!isPayoutCancelled && !isPaymentConfirmed && eventType === 'cancel'">
                  <button class="btn btn-border lg inverse ml-3" @click="cancelPayoutHandler">Cancel payout</button>
                </template>
                <button v-else-if="!isPaymentConfirmed && currentShop.type === 'destination' && eventType === 'delete'" class="btn btn-border btn-delete lg ml-3" @click="deletePayoutHandler()">Delete payout</button>
              </span>
            </div>
          </div>
        </aside>
      </transition>
    </v-card>
  </v-dialog>
</template>
